// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("reader-image-small.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReaderIcon {\n    height: 44px;\n    width: 32px;\n    display: block;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: 100%;\n}\n\n", "",{"version":3,"sources":["webpack://./src/components/Icon/reader/ReaderIcon.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,cAAc;IACd,yDAA+C;IAC/C,qBAAqB;AACzB","sourcesContent":[".ReaderIcon {\n    height: 44px;\n    width: 32px;\n    display: block;\n    background-image: url('reader-image-small.png');\n    background-size: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
